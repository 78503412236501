
















































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Company from '@/models/Company';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import AnalyticsRepository from '@/api/repositories/AnalyticsRepository';

const CompanyStore = namespace('company');
const CustomerStore = namespace('customer');
const UserStore = namespace('user');

/**
 * Sidebar component for navigation items.
 */
@Component({
  components: {
    UserInitialsComponent,
    NavigationProfileComponent: () => import(
        /* webpackChunkName: "NavigationProfileComponent" */
        '@/components/shared/NavigationProfile.component.vue'),
  },
})
export default class HeaderBarComponent extends Vue {

  @CompanyStore.Mutation('activeCompany')
  private activeCompany!: (company: Company) => void;
  @CompanyStore.Getter('activeCompany')
  private _company?: Company;

  get company(): Company | undefined {
    return this._company ? this._company : undefined;
  }


  public applicationName: string = process.env.VUE_APP_TITLE!;
  private showHelpTooltip: boolean = false;

  /**
   * is true when the user has unread notifications
   * TODO: implement notifications
   */
  private hasNotifications: boolean = false;


  /**
   * the information of the searchBar
   */
  private searchString: string = '';
  /**
   * filters which objects should be returned from the searchQuery.
   * In the future a user could filter by himself by clicking a button
   */
  private searchFilter: string = '';
  private searchBarItems: any[] = [];
  private searchBarDebounce: number = 0;
  /**
   * is true  when the program is waiting for the api
   */
  private searchBarLoading = false;
  private analyticsRepository: AnalyticsRepository = RepositoryFactory.get('analytics');

  private mounted() {
    this.setFilters();
  }

  private setFilters() {
    if (Vue.userRoleHandler.isSuperAdmin()) {
      this.searchFilter = 'location,customer,user,company';
    } else if (Vue.userRoleHandler.isTenantAdmin()) {
      this.searchFilter = 'location,customer,user';
    } else {
      this.searchFilter = 'location,customer';
    }
  }

  /**
   * Watches the Search input and loads the SearchItems with a debounce
   * This is done, to reduce the requests from the searchBar
   * only sends a request if the string has a length of 3+ to reduce traffic
   */
  @Watch('searchString')
  private onUpdateSearchBarItems() {
    window.clearTimeout(this.searchBarDebounce);
    if (this.searchString.length >= 2) {
      this.searchBarLoading = true;
      this.searchBarDebounce = window.setTimeout(() => {
        this.loadSearchBarItems();
      }, 600);
    } else {
      this.searchBarLoading = false;
    }
  }

  /**
   *  loads the Items of the Searchbar and sorts them
   */
  private async loadSearchBarItems() {
    try {
      this.searchBarItems = await this.analyticsRepository.getNavSearchBarResults(this.searchString, this.searchFilter, this.company?.id);
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
    this.searchBarItems.sort((x, y) => y.relevance - x.relevance);
    this.searchBarLoading = false;
  }

  /**
   * Method to get highlightable names for the searchBar menu
   */
  private getItemName(item: any): string[] {
    if (item.type === 'user') {
      return this.showMatches(item, 'firstName').concat([''], [' '], ['']).concat(this.showMatches(item, 'lastName'));
    } else {
      return this.showMatches(item, 'name');
    }
  }

  /**
   * Getter to show the backButton. If the path view is nested, the path has a minimum of 4 '/'. So split at these
   * @private
   */
  private get showBackButton() {
    return this.$route.path.split('/').length > 4;
  }

  /**
   * converts the input of the searchBar to searchString if it is valid
   */
  private onSearchInputUpdate(queryData: string) {
    if (queryData == null) {
      return;
    }
    this.searchString = queryData;
  }

  /**
   * Method which separates a String in item according to a match object into an array
   * The uneven indexes include the hits of the match and are supposed to be highlighted.
   * If the check for the Key is altered, this method could also be used for bigger Strings.
   * @param item the return value of the search-query
   * @param key the name of the attribute which is supposed to be highlighted
   */

  private showMatches(item: any, key: string): string[] {
    let matchArray: string[] = [];
    let textValue = '';

    switch (key) {
      case 'name': {
        textValue = item.value.name;
        break;
      }
      case 'firstName': {
        textValue = item.value.firstName;
        matchArray = [textValue];
        break;
      }
      case 'lastName': {
        textValue = item.value.lastName;
        matchArray = [textValue];
        break;
      }
      case 'email': {
        textValue = item.value.email;
        break;
      }
      case 'description': {
        textValue = item.value.description;
        break;
      }
    }
    // traverse through all matches to find relevant ones
    for (const match of item.matches) {
      if (match.key === key) {
        // if the key fits open helper method, which returns an array
        matchArray = this.concatMatch(textValue, match, matchArray.length > 0 ? matchArray : undefined);
      }
    }
    return matchArray;
  }

  /**
   * helper method of showMatches. For n matches always returns an array of length= 2n+1
   */
  private concatMatch(text: string, match: any, matchArray?: string[]): string[] {

    const matchString = text.substring(match.index, match.index + match.length);
    const newMatch = [
      text.substring(0, match.index),
      matchString,
      text.substring(match.index + match.length, text.length),
    ];
    if (!matchArray) {
      return newMatch;
    } else {
      let matchCopy: string[] = [];
      matchCopy = matchCopy.concat(matchArray);
      matchCopy.pop();
      /*
      check if matches overlap. If two matches don't overlap the newMatch[0] is longer
      or equal to the length of the matchArray up to the last match
       */
      if (newMatch[0].length >= matchCopy.join('').length) {
        // correct text between matches
        matchArray[matchArray.length - 1] = matchArray[matchArray.length - 1].split(matchString, 2)[0];
        // add the match and the rest of the text
        matchArray.push(newMatch[1]);
        matchArray.push(newMatch[2]);
      } else {
        // cut the overlap from the previous match
        const residue = matchArray[matchArray.length - 2] + matchArray[matchArray.length - 1];
        matchArray[matchArray.length - 2] = residue.split(matchString, 2)[0];
        // no text between matches
        matchArray[matchArray.length - 1] = '';
        // add the match and the rest of the text
        matchArray.push(newMatch[1]);
        matchArray.push(newMatch[2]);
      }
    }
    return matchArray;
  }

  /**
   * click events of the searchBar
   */
  private onRowClicked(item: any) {
    // remove focus if entry is selected
    (this.$refs.uniSearch as any).blur();
    switch (item.type) {
      case 'user': {
        this.$router.push({
          name: 'userDetails',
          params: {
            companyId: this.$route.params.companyId,
            userId: item.value.id!,
          },
        });
        break;
      }
      case 'customer': {
        this.$router.push({
          name: 'customerDashboard',
          params: {
            companyId: this.$route.params.companyId,
            customerId: item.value.id!,
          },
        });
        break;
      }
      case 'location': {
        this.$router.push({
          name: 'locationDashboard',
          params: {
            companyId: this.$route.params.companyId,
            customerId: item.value.customer.id!,
            locationId: item.value.id!,
          },
        });
        break;
      }
      case 'company': {
        this.activeCompany(item.value);
        this.$router.push({
          name: 'companyDashboard',
          params: {
            companyId: item.value.id,
          },
        });
        break;
      }
    }
    this.clearSearchBar();
  }

  /**
   * Go back one step
   * @private
   */
  private goBack() {
    this.$router.go(-1);
  }

  private clearSearchBar() {
    this.searchBarItems = [];
    // @ts-ignore
    this.$refs.uniSearch.reset();
  }

  private get getMenuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      offsetY: true,
      bottom: true,
      rounded: 'lg',
      offsetOverflow: true,
      nudgeBottom: 10,
      contentClass: 'custom-form-select-menu',
    };
  }

  private getName(value: any) {
    return value.hasOwnProperty('name') ? value.name : value.firstName + ' ' + value.lastName;
  }
}
